import React, { useState } from "react";
import {
  View,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
  TouchableOpacity,
} from "react-native";
import {
  Card,
  Searchbar,
  TouchableRipple,
  TextInput,
  Modal,
  Portal,
  Provider,
} from "react-native-paper";
import { MaterialIcons, AntDesign } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import styles from "../components/style/styles";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import Header from "../components/core/Header";
import Button from "../components/Button";
import CoreFooter from "../components/core/CoreFooter";
import { useTranslation } from 'react-i18next';

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const AddItemSearch = ({ navigation, route }) => {
  const { t } = useTranslation();
  const pickOrg = route.params.organisationId;
  const gender = route.params?.gender;
  const clothType = route.params?.clothType;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const [brandData, setBrandData] = useState(searchItem);
  const [message, setMesssage] = useState("");
  const [brandInputFeild, setBrandInputFeild] = useState(false);
  const [addOtherBrand, setAddOtherBrand] = useState("");
  const [openBrandModal, setOpenBrandModal] = useState(false);
  const hideBrandModal = () => setOpenBrandModal(false);
  const hadnleSearch = (e) => {
    const value = e;
    const filteredName = searchItem.filter((item) => {
      return (
        item.cardTitle1?.toLowerCase().match(value.trim()) ||
        item.cardTitle1?.toUpperCase().match(value.trim()) ||
        item.cardTitle1?.match(value.trim()) ||
        item.cardTitle2?.toLowerCase().match(value.trim()) ||
        item.cardTitle2?.toUpperCase().match(value.trim()) ||
        item.cardTitle2?.match(value.trim()) ||
        item.cardTitle3?.toLowerCase().match(value.trim()) ||
        item.cardTitle3?.toUpperCase().match(value.trim()) ||
        item.cardTitle3?.match(value.trim())
      );
    });
    if (value.trim() === "") {
      setMesssage("");
      setBrandData(searchItem);
    } else if (filteredName.length === 0) {
      setMesssage("Ingen träff på varumärket, välj Annat längst ner i listan");
    } else {
      setBrandData(filteredName);
    }
  };

  const customPageList = orgDetail[0]?.customPages
    ? JSON.parse(orgDetail[0]?.customPages)
    : {};

  const handleNext = (item) => {
    let pageUrl = "AddPrice"
    if (orgDetail[0]?.urlPath === 'sandqvist') {
      // if (customPageList?.isHidePrice) {
      //   pageUrl = "AddItemProductType"
      // } else {
      pageUrl = "AddItemProductType"
      // }
    }
    navigation.navigate(pageUrl, {
      gender,
      clothType,
      brandType: item,
      organisationId: pickOrg,
    });
  };
  const additemoption_desc = "Vi söker bara plaggen som finns listade här - Om du skickar in något annat så kommer det antagligen be nekat då det inte passar vårat sortiment"
  return (
    <Provider>
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        }}
      >
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          enabled
        >
          <Header
            navigation={navigation}
            route={route}
            isHeaderItem
            progress={0.5}
            steps={3}
          />
          <ScrollView>
            <View style={[styles.Landingcontainer]} style={{ height: 100 }}>
              <View>
                <View style={[styles.titlesearchContainer]}>
                  <CoreText
                    textTitle={t("app.addItemSearch_title", "Vad är plaggets varumärke?")}
                    style={[
                      styles.Title,
                      {
                        paddingTop: 0,
                        marginTop: 24,
                        color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                        textAlign: "center",
                      },
                    ]}
                    fontType={MontserratMedium}
                  />
                </View>
              </View>
              <View style={[styles.searchmainContent]}>
                <Searchbar
                  placeholder={t("app.search_brand", "Sök varumärke")}
                  style={[styles.searchBar]}
                  placeholderTextColor={styles.TEXT_GRAY}
                  inputStyle={styles.searchInput}
                  icon={() => (
                    <MaterialIcons
                      name="search"
                      size={20}
                      color={Colors.TEXT_GRAY}
                    />
                  )}
                  theme={{ colors: { text: Colors.BLACK } }}
                  onChangeText={(e) => hadnleSearch(e)}
                />
                <View>
                  {message ? (
                    <View>
                      <CoreText
                        textTitle={message}
                        style={styles.titleStyle}
                        fontType={MontserratBold}
                      />
                    </View>
                  ) : (
                    brandData?.map((item, index) => (
                      <View key={index}>
                        <CoreText
                          textTitle={item.searchTitle}
                          style={styles.titleStyle}
                          fontType={MontserratBold}
                        />
                        <View style={{ marginBottom: 6 }}>
                          <Card
                            style={{
                              borderRadius: 12,
                              shadowColor: "transparent",
                              backgroundColor: "#fff",
                            }}
                          >
                            <TouchableRipple
                              style={[
                                styles.cardStyle,
                                { paddingVertical: 16 },
                              ]}
                              // onPress={() =>
                              //   navigation.navigate("AddPrice", {
                              //     gender,
                              //     clothType,
                              //     brandType: item.cardTitle1,
                              //     organisationId: pickOrg,
                              //   })
                              // }
                              onPress={() => handleNext(item?.cardTitle1)}
                              rippleColor="rgba(0, 0, 0, .32)"
                            >
                              <CoreText
                                textTitle={item.cardTitle1}
                                style={styles.headerTitle}
                                fontType={MontserratBold}
                              />
                            </TouchableRipple>
                          </Card>
                        </View>
                        {item.cardTitle2 && (
                          <View style={{ marginBottom: 6 }}>
                            <Card
                              style={{
                                borderRadius: 12,
                                shadowColor: "transparent",
                                backgroundColor: "#fff",
                              }}
                            >
                              <TouchableRipple
                                // onPress={() =>
                                //   navigation.navigate("AddPrice", {
                                //     gender,
                                //     clothType,
                                //     brandType: item.cardTitle2,
                                //     organisationId: pickOrg,
                                //   })
                                // }
                                onPress={() => handleNext(item?.cardTitle2)}
                                style={[
                                  styles.cardStyle,
                                  { paddingVertical: 16 },
                                ]}
                                rippleColor="rgba(0, 0, 0, .32)"
                              >
                                <CoreText
                                  textTitle={item.cardTitle2}
                                  style={styles.headerTitle}
                                  fontType={MontserratBold}
                                />
                              </TouchableRipple>
                            </Card>
                          </View>
                        )}
                        {item.cardTitle3 && (
                          <View style={{ marginBottom: 6 }}>
                            <Card
                              style={{
                                borderRadius: 12,
                                shadowColor: "transparent",
                                backgroundColor: "#fff",
                              }}
                            >
                              <TouchableRipple
                                // onPress={() =>
                                //   navigation.navigate("AddPrice", {
                                //     gender,
                                //     clothType,
                                //     brandType: item.cardTitle3,
                                //     organisationId: pickOrg,
                                //   })
                                // }
                                onPress={() => handleNext(item?.cardTitle3)}
                                style={[
                                  styles.cardStyle,
                                  { paddingVertical: 16 },
                                ]}
                                rippleColor="rgba(0, 0, 0, .32)"
                              >
                                <CoreText
                                  textTitle={item.cardTitle3}
                                  style={styles.headerTitle}
                                  fontType={MontserratBold}
                                />
                              </TouchableRipple>
                            </Card>
                          </View>
                        )}
                      </View>
                    ))
                  )}
                </View>
                <View style={{ marginBottom: 20 }}>
                  <CoreText
                    textTitle={t("app.other_brand_title", "Hittar du inte rätt varumärke?")}
                    style={{
                      marginTop: 50,
                      fontSize: 14,
                      fontWeight: "bold",
                      marginBottom: 15,
                    }}
                    fontType={MontserratBold}
                  />
                  <TouchableOpacity
                    onPress={() => {
                      setOpenBrandModal(true);
                    }}
                    disabled={brandInputFeild}
                  >
                    <CoreText
                      textTitle={t("app.other_brand", "Annat varumärke")}
                      style={[styles.newBrandData]}
                      fontType={MontserratMedium}
                    />
                  </TouchableOpacity>
                  {brandInputFeild && (
                    <>
                      <TextInput
                        label={
                          <CoreText
                            textTitle={t("app.brand_placeholder", "Skriv vilken typ av märke det är")}
                            style={[styles.input]}
                            fontType={MontserratMedium}
                            isInput
                          />
                        }
                        style={[
                          styles.inputFiledstyle,
                          {
                            marginVertical: 25,
                          },
                        ]}
                        value={addOtherBrand}
                        keyboardType="default"
                        placeholderTextColor="#000"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        maxLength={200}
                        onChange={(e) => {
                          setAddOtherBrand(e.target.value);
                        }}
                        theme={{ colors: { text: Colors.BLACK } }}
                      />
                      <Button
                        style={{ marginBottom: 30 }}
                        btnTitle={t("app.continue", "Fortsätt")}
                        lineHeight={32}
                        // onPress={() =>
                        //   navigation.navigate("AddPrice", {
                        //     gender,
                        //     clothType,
                        //     brandType: addOtherBrand,
                        //     organisationId: pickOrg,
                        //   })
                        // }
                        onPress={() => handleNext(addOtherBrand)}
                        disableButtondata={!addOtherBrand}
                        disabled={!addOtherBrand}
                      />
                    </>
                  )}
                </View>
                <Portal>
                  <Modal
                    visible={openBrandModal}
                    onDismiss={hideBrandModal}
                    contentContainerStyle={{
                      backgroundColor: "white",
                      marginVertical: 0,
                      marginHorizontal: 15,
                      borderRadius: 10,
                      alignSelf: "center",
                      maxWidth: 400,
                    }}
                  >
                    <View
                      style={{
                        textAlign: "end",
                        paddingHorizontal: 10,
                        paddingTop: 10,
                      }}
                    >
                      <AntDesign
                        name="closesquareo"
                        size={24}
                        color="black"
                        onPress={() => hideBrandModal()}
                      />
                    </View>
                    <View
                      style={{ paddingVertical: 15, paddingHorizontal: 15 }}
                    >
                      <CoreText
                        textTitle={t("app.additemoption_desc", additemoption_desc)}
                        style={styles.codeWebDesign}
                        fontType={MontserratMedium}
                      />
                    </View>
                    <View
                      style={{
                        paddingBottom: 15,
                        paddingHorizontal: 15,
                        display: "flex",
                        alignItems: "end",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setBrandInputFeild(true);
                          setOpenBrandModal(false);
                        }}
                      >
                        <CoreText
                          textTitle="OK"
                          fontType={MontserratMedium}
                          style={styles.butttonOkData}
                        />
                      </TouchableOpacity>
                    </View>
                  </Modal>
                </Portal>
              </View>
              <CoreFooter />
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </Provider>
  );
};
export default AddItemSearch;

const searchItem = [
  {
    searchTitle: "A",
    cardTitle1: "Adidas",
    cardTitle2: "Arc'teryx",
    cardTitle3: "Armada",
  },
  {
    searchTitle: "B",
    cardTitle1: "Bergans",
    cardTitle2: "Black Diamond",
    cardTitle3: "Burton",
  },
  {
    searchTitle: "C",
    cardTitle1: "Colmar",
    cardTitle2: "Columbia",
  },

  {
    searchTitle: "D",
    cardTitle1: "Didriksson",
  },
  {
    searchTitle: "E",
    cardTitle1: "EQPE",
  },
  {
    searchTitle: "F",
    cardTitle1: "Fjällräven",
    cardTitle2: "Fusalp",
  },
  {
    searchTitle: "G",
    cardTitle1: "Goldbergh",
  },
  {
    searchTitle: "H",
    cardTitle1: "Haglöfs",
    cardTitle2: "Helly Hansen",
    cardTitle3: "Houdini",
  },
  {
    searchTitle: "J",
    cardTitle1: "J.Lindeberg",
  },
  {
    searchTitle: "K",
    cardTitle1: "Klättermusen",
  },
  {
    searchTitle: "N",
    cardTitle1: "Norrøna",
  },
  {
    searchTitle: "O",
    cardTitle1: "Oakley",
  },
  {
    searchTitle: "P",
    cardTitle1: "Patagonia",
    cardTitle2: "Peak Performance",
    cardTitle3: "POC",
  },
  {
    searchTitle: "S",
    cardTitle1: "Sail Racing",
    cardTitle2: "Salomon",
    cardTitle3: "Scott",
    cardTitle3: "Stellar Equipment",
    cardTitle4: "Sweet Protection",
  },
  {
    searchTitle: "T",
    cardTitle1: "The North Face",
  },
  {
    searchTitle: "W",
    cardTitle1: "WearColour",
  },
];
